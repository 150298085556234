import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// components
import { useTheme } from '@mui/material/styles';
import { FOOTER } from '@raas/shared/src/shared-config-global';
import { useSettingsContext } from '@raas/shared/src/components/minimals/settings';
// hooks
import useResponsive from '@raas/shared/src/hooks/useResponsive';
//
import Header from '@raas/shared/src/components/header';
import Main from './Main';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';
import Footer from './Footer';
// ----------------------------------------------------------------------
export default function DashboardLayout() {
    var themeLayout = useSettingsContext().themeLayout;
    var theme = useTheme();
    var isDesktop = useResponsive('up', 'lg');
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var handleOpen = function () { return setOpen(true); };
    var handleClose = function () { return setOpen(false); };
    var renderNavigation = function () {
        switch (themeLayout) {
            case "horizontal":
                return isDesktop ? _jsx(NavHorizontal, {}) : _jsx(NavVertical, { openNav: open, onCloseNav: handleClose });
            case "mini":
                return isDesktop ? _jsx(NavMini, {}) : _jsx(NavVertical, { openNav: open, onCloseNav: handleClose });
            default:
                return _jsx(NavVertical, { openNav: open, onCloseNav: handleClose });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Header, { onOpenNav: handleOpen }), _jsxs(Box, { sx: {
                    display: { lg: 'flex' },
                }, children: [renderNavigation(), _jsx(Main, { sx: {
                            backgroundColor: theme.palette.background.neutral,
                            minHeight: {
                                lg: "calc(100vh - ".concat(FOOTER.H_MAIN_DESKTOP + 12, "px)"),
                                marginTop: '12px',
                            },
                        }, children: _jsx(Outlet, {}) })] }), _jsx(Footer, {})] }));
}
