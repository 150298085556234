import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { NAV } from '@raas/shared/src/shared-config-global';
import { useSettingsContext } from '@raas/shared/src/components/minimals/settings';
import { useLocales } from '@raas/shared/src/locales';
export default function Footer() {
    var theme = useTheme();
    var themeLayout = useSettingsContext().themeLayout;
    var translate = useLocales().translate;
    var isNavMini = themeLayout === 'mini';
    var linkAttributes = [
        {
            href: "https://www.kontrol.tech/terms-and-conditions/",
            label: translate('common.legalNotice'),
        },
        {
            href: "https://www.kontrol.tech/legal/",
            label: translate('common.impressum'),
        },
    ];
    return (_jsx(Box, { component: "footer", sx: {
            py: 3,
            px: 2,
            ml: 'auto',
            mt: 'auto',
            width: "calc(100% - ".concat((isNavMini ? NAV.W_DASHBOARD_MINI : NAV.W_DASHBOARD) + 1, "px)"),
            backgroundColor: theme.palette.background.default,
        }, children: _jsx(Container, { children: _jsxs(Stack, { display: "flex", direction: "row", alignItems: "center", justifyContent: "space-between", spacing: { xs: 1.5, sm: 2.5, lg: 3.5 }, children: [_jsxs(Typography, { variant: "body2", color: "text.secondary", component: "div", children: ["".concat(translate('common.copyright'), " \u00A9"), _jsx(Link, { sx: { px: '4px' }, color: "inherit", href: "https://www.kontrol.tech/", children: translate('common.kontrolTitle') }), new Date().getFullYear()] }), _jsx(Typography, { variant: "body1", component: "div", sx: { display: "flex", justifyContent: "center", alignItems: "center", gap: 3 }, children: linkAttributes.map(function (item, index) { return (_jsx(Link, { color: "inherit", href: item.href, target: "_blank", rel: "noreferrer", children: item.label }, "footer-link-".concat(index))); }) })] }) }) }));
}
