var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
// components
import { HEADER, NAV } from '@raas/shared/src/shared-config-global';
import { useSettingsContext } from '@raas/shared/src/components/minimals/settings';
// hooks
import useResponsive from '@raas/shared/src/hooks/useResponsive';
// ----------------------------------------------------------------------
var SPACING = 8;
export default function Main(_a) {
    var children = _a.children, sx = _a.sx, other = __rest(_a, ["children", "sx"]);
    var themeLayout = useSettingsContext().themeLayout;
    var isDesktop = useResponsive('up', 'lg');
    var isNavHorizontal = themeLayout === 'horizontal';
    var isNavMini = themeLayout === 'mini';
    var desktopPadding = {
        px: 2,
        pt: "".concat(HEADER.H_DASHBOARD_DESKTOP + 80, "px"),
        pb: "".concat(HEADER.H_DASHBOARD_DESKTOP + SPACING, "px"),
    };
    var horizontalLayoutStyle = __assign({ pt: "".concat(HEADER.H_MOBILE + SPACING, "px"), pb: "".concat(HEADER.H_MOBILE + SPACING, "px") }, (isDesktop && desktopPadding));
    var verticalLayoutStyle = __assign({ flexGrow: 1, py: "".concat(HEADER.H_MOBILE + SPACING, "px") }, (isDesktop && __assign(__assign({}, desktopPadding), { width: "calc(100% - ".concat(isNavMini ? NAV.W_DASHBOARD_MINI : NAV.W_DASHBOARD, "px)") })));
    return (_jsx(Box, __assign({ component: "main", sx: __assign(__assign({}, (isNavHorizontal ? horizontalLayoutStyle : verticalLayoutStyle)), sx) }, other, { children: children })));
}
